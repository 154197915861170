<template>
  <div
    id="app"
    :class="[appIntersectClass, isTouchDevice, isBrowserIE]"
    :style="{
      minHeight: appHeight
    }"
  >
    <data-layer-push />
    <cookie-disclaimer />

    <header-layout v-if="!hideHeader" />
    <router-view />
    <component
      :is="footerComponent"
      v-if="!hideFooter"
      :additional-portals_mixin="showAdditionalPortals"
    />


    <uid-track v-if="uidTrackReady" />
  </div>
</template>

<script>
import HeaderLayout from 'components/Header.vue';
import DeviceHelper from 'mixins/DeviceHelper';
import { isIE } from 'mobile-device-detect';
import DataLayerPush from 'components/DataLayerPush.vue';
import UidTrack from 'components/UidTrack.vue';
// import { hydrateWhenVisible } from 'vue-lazy-hydration';
import hydrateOrObserveWhenVisible from 'utils/hydrateOrObserveWhenVisible';

import structuredDataOrganization from 'utils/structureddata/organization';
import structuredDataSearchbox from 'utils/structureddata/searchbox';
import CookieDisclaimer from 'components/CookieDisclaimer.vue';

import { mapGetters } from 'vuex';
import {
  getCookieVal,
} from 'mixins/composition/CookieApi';
import defaultContentGroupPush from 'mixins/defaultContentGroupPush';
import UserApi from 'mixins/UserApi';
import config from 'config/appConfig';
import cdn from 'config/cdn';

const { showCanonical } = config;

export default {
  name: 'App',
  components: {
    HeaderLayout,
    UidTrack,
    DataLayerPush,
    CookieDisclaimer,
    FooterLayout: hydrateOrObserveWhenVisible(() => import(/* webpackChunkName: "lazyhydrate-footer" */ 'components/Footer.vue'), {
      observerOptions: {
        rootMargin: '500px',
      },
      loadingComponent: false,
    }),
    BookingFooter: hydrateOrObserveWhenVisible(() => import(/* webpackChunkName: "lazyhydrate-booking-footer" */ 'components/BookingFooter.vue')),
  },
  mixins: [
    DeviceHelper,
    defaultContentGroupPush,
    UserApi,
  ],
  setup() {
    return {
      getCookieVal,
    };
  },
  data() {
    return {
      uidTrackReady: false,
      issueCollectorInitialized: false,
    };
  },
  i18nOptions: { namespaces: 'common' },
  metaInfo() {
    return this.metaData;
  },
  computed: {
    ...mapGetters({ getMapStatus: 'getMapStatus' }),
    appIntersectClass() {
      const appIntersectClassStore = this.$store.getters.getAppIntersectClass;
      let appIntersectClass = '';
      Object.keys(appIntersectClassStore).forEach((elemId) => {
        appIntersectClass += `${elemId}_${appIntersectClassStore[elemId]} `;
      });
      return appIntersectClass;
    },
    isTouchDevice() {
      let isTouchDeviceClass = false;
      if (this.dh_isMatching('pointerTouch') === true) {
        isTouchDeviceClass = 'isTouchDevice';
      }
      return isTouchDeviceClass;
    },
    isBrowserIE() {
      let isBrowserIEClass = false;
      if (isIE) {
        isBrowserIEClass = 'browserIE';
      }
      return isBrowserIEClass;
    },
    appHeight() {
      return this.$store.state.app.router.navigationFromPopstate ? `${this.$store.state.app.router.lastAppHeight}px` : '0px';
    },
    metaData() {
      /* add to home screen is kinda funky for IPhpnes, for WLV revision is undefined and otherwise it will load the normal
      // e-dom icon */
      const appleIconVersion = typeof process.env.REVISION !== 'undefined' ? process.env.REVISION : '';
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: this.$t('common:metaData.title'),
        // all titles will be injected into this template
        // titleTemplate: '%s - e-domizil.de',
        link: [
          {
            vmid: 'clientcdn',
            rel: 'preconnect',
            href: `//${cdn}`
          },
          {
            once: true, rel: 'icon', type: 'image/png', sizes: '32x32', href: `/img/icons/favicon-32x32.png?v=${appleIconVersion}`,
          },
          {
            once: true, rel: 'icon', type: 'image/png', sizes: '16x16', href: `/img/icons/favicon-16x16.png?v=${appleIconVersion}`,
          },
          { once: true, rel: 'apple-touch-icon', href: `/img/icons/appleTouch_xl.png?v=${appleIconVersion}` },
          { once: true, rel: 'mask-icon', href: `/img/icons/safari-pinned-tab.svg?v=${appleIconVersion}` },
        ],
        htmlAttrs: {
          lang: this.$i18n.i18next.language,
        },
        script: [
          structuredDataOrganization,
          structuredDataSearchbox,
        ],
        meta: [
          { charset: 'utf-8' },
          // eslint-disable-next-line no-undef
          { once: true, name: 'theme-color', content: this.$t('common:metaData.themeColor') },
          { once: true, name: 'apple-mobile-web-app-capable', content: 'yes' },
          { once: true, name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
          { once: true, name: 'msapplication-TileImage', content: `/img/icons/msapplication-icon-144x144.png?v=${process.env.REVISION}` },
          { once: true, name: 'msapplication-TileColor', content: '#FFFFFF' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=yes' },
          { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
          { name: 'copyright', content: this.$t('common:metaData.copyright') },
          { property: 'fb:admins', content: this.$t('common:metaData.fbAdmins') },
          { property: 'fb:page_id', content: this.$t('common:metaData.fbPageId') },
          { vmid: 'description', name: 'description', content: this.$t('common:metaData.description') },
          // { vmid: 'keywords', name: 'keywords', content: 'Ferienhaus, Ferienwohnung, Ferienhäuser, Ferienwohnungen, buchen, mieten, Urlaub mit Hund, Familienurlaub, Skiurlaub, Finca' },
          // config.router.ota.domain === null || config.router.to.whitelist.includes(this.$route.path) ? { vmid: 'robots', name: 'robots', content: 'index, follow' } : { vmid: 'robots', name: 'robots', content: 'noindex, nofollow' },
        ],
      };
    },
    userDataStatus() {
      return this.$store.getters.userDataStatus;
    },
    // for components with a custom footer
    footerComponent() {
      return this.$route.meta.footerComponent || 'FooterLayout';
    },
    hideFooter() {
      return this.$route.meta.name === 'BookingStalker'
        || this.$route.meta.name === 'LocaleEditor'
        || this.$route.meta.name === 'AffiliateRedirect';
    },
    showAdditionalPortals() {
      return this.$route.meta.name === 'Home';
    },
    // for views with a custom header
    hideHeader() {
      if (this.$route.meta.name === null || typeof this.$route.meta.name === 'undefined') return false;
      return this.$route.meta.name === 'Request'
          || this.$route.meta.name.indexOf('Booking') !== -1
          || this.$route.meta.name.indexOf('whoami_Search') !== -1
          || this.$route.meta.name.indexOf('whoami_Object') !== -1
          || this.$route.meta.name.match(/Search/i) !== null
          || this.$route.meta.name.match(/ObjectPage/i) !== null
          || this.$route.meta.name === 'AffiliateRedirect';
    },
  },
  watch: {
    userDataStatus: {
      handler: 'initIssueCollector',
    },
  },
  mounted() {
    this.$store.commit('SET_CANONICAL', showCanonical); // EDVUE-1889, activate client canonical for SEO tools

    // tracking only needed for client side
    this.uidTrackReady = true;
  },
  methods: {
    initIssueCollector() {
    },
  },
};

</script>

<style lang="scss" src='scss/fonts.scss'></style>
<style lang="scss">
  @import "scss/bootstrap/mandatory";
  @import "node_modules/bootstrap/scss/root";
  @import "node_modules/bootstrap/scss/reboot";
  @import "node_modules/bootstrap/scss/type";
</style>
<style lang="scss" src='scss/custombootstrap.scss'></style>
<!-- the next booststrap modules are imported and purged independentaly because it seems to work more efficiently -->
<!-- please do not uncomment the same modules in custombootstrap.scss -->
<!-- <style lang="scss" src='scss/bootstrap/utilities/spacing.scss'></style> -->
<!-- <style lang="scss" src='scss/bootstrap/utilities/display.scss'></style> -->
<!-- <style lang="scss" src='scss/bootstrap/utilities/flex.scss'></style> -->
<!-- <style lang="scss" src='scss/bootstrap/utilities/float.scss'></style> -->
<style lang="scss" src='scss/main.scss'></style>

<style lang="scss">
#atlwdg-trigger {
  z-index: 200;
}

@media print {
  section.footer {
    display: none;
  }
}

</style>
